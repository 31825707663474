/*--------------------------------------------------------------
3. content
--------------------------------------------------------------*/

/*3.1 wpo-hero-slider*/

.wpo-hero-slider,
.static-hero,
.wpo-hero-slider-s2 {
  width: 100%;
  height: 900px;
  display: flex;
  position: relative;
  z-index: 0;

  @include media-query(991px) {
    height: 600px;
  }

  @include media-query(767px) {
    height: 500px;
  }

  .hero-slide {
    height: 900px;

    @include media-query(1199px) {
      height: 680px;
    }

    @include media-query(991px) {
      height: 600px;
    }

    @include media-query(767px) {
      height: 500px;
    }
  }

  .swiper-container,
  .hero-container {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  .slide-inner,
  .hero-inner {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    z-index: 1;

    &:before {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: #040128;
      content: "";
      opacity: 0.6;
      z-index: -1;
    }

    .slide-content {
      padding-left: 240px;

      @media (max-width: 1700px) {
        padding-left: 140px;
      }
      @media (max-width: 991px) {
        padding-left: 30px;
      }
      @media (max-width: 767px) {
        padding-left: 10px;
      }
    }
  }

  .slick-prev,
  .slick-next {
    background-color: transparentize($theme-primary-color, 0.3);
    width: 45px;
    height: 45px;
    z-index: 10;
    @include rounded-border(50%);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
    border: 2px solid $theme-primary-color;

    &:hover {
      background-color: $theme-primary-color;
    }
  }

  .slick-prev {
    left: 0px;

    @include media-query(767px) {
      display: none !important;
    }

    &:before {
      font-family: "themify";
      content: "\e629";
      opacity: 1;
    }
  }

  .slick-next {
    right: 0px;

    @include media-query(767px) {
      display: none !important;
    }

    &:before {
      font-family: "themify";
      content: "\e628";
      opacity: 1;
    }
  }

  &:hover {
    .slick-next {
      right: 20px;
      opacity: 1;
      visibility: visible;
    }

    .slick-prev {
      left: 20px;
      opacity: 1;
      visibility: visible;
    }
  }
}

.wpo-hero-slider,
.static-hero,
.wpo-hero-slider-s2 {
  @include media-query(1199px) {
    height: 680px;
  }

  @include media-query(991px) {
    height: 600px;
  }

  @include media-query(767px) {
    height: 500px;
  }

  .gradient-overlay {
    @include linear-gradient-bg(left, $dark-gray 45%, transparent 65%);
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
    opacity: 0.6;
  }

  .gradient-overlay + .container {
    position: relative;
    z-index: 11;
  }

  .swiper-slide {
    position: relative;
    z-index: 11;
  }

  .wpo-hero-title-top {
    span {
      font-size: 22px;
      color: #e4e4e4;
      font-family: $heading-font;

      @include media-query(767px) {
        font-size: 15px;
      }
    }
  }

  .slide-title {
    max-width: 712px;

    @include media-query(1199px) {
      max-width: 555px;
    }

    h2 {
      font-size: 70px;
      font-weight: 900;
      line-height: 90px;
      margin: 10px 0 15px;
      color: $white;

      @include media-query(1199px) {
        font-size: 50px;
        font-size: calc-rem-value(50);
      }

      @include media-query(991px) {
        font-size: 40px;
        font-size: calc-rem-value(40);
        line-height: 55px;
      }

      @include media-query(767px) {
        font-size: 30px;
        font-size: calc-rem-value(30);
        line-height: 36px;
      }
    }
  }

  .slide-text {
    max-width: 680px;

    @include media-query(768px) {
      max-width: 500px;
    }

    p {
      font-size: 22px;
      color: $white;
      line-height: 35px;
      max-width: 680px;
      color: #e2e2e2;

      @include media-query(991px) {
        font-size: 18px;
        font-size: calc-rem-value(18);
      }

      @include media-query(767px) {
        font-size: 16px;
        font-size: calc-rem-value(16);
        line-height: 22px;
      }

      @include media-query(460px) {
        font-size: 0.8rem;
      }
    }
  }

  .slide-btns {
    @include media-query(991px) {
      max-width: 400px;
    }

    .theme-btn {
      border-radius: 0;
      color: $dark-gray;
      font-weight: 600;
      font-size: 18px;

      &:after {
        border-radius: 0;
      }
    }
  }

  .slide-btns .hero-video-btn {
    margin-left: 40px;

    @include media-query(767px) {
      margin-left: 20px;
    }
  }
}

.wpo-hero-slider-s2 {
  height: 1000px;

  @include media-query(1199px) {
    height: 680px;
  }

  @include media-query(991px) {
    height: 600px;
  }

  @include media-query(767px) {
    height: 500px;
  }

  .hero-slide {
    height: 1000px;

    @include media-query(1199px) {
      height: 680px;
    }

    @include media-query(991px) {
      height: 600px;
    }

    @include media-query(767px) {
      height: 500px;
    }
  }

  .hero-inner,
  .slide-inner {
    .slide-content {
      padding-bottom: 0;
      padding-top: 40px;
    }
  }
}

/*-------------------------------------------
	hero style 1
--------------------------------------------*/
.static-hero {
  background: #29395b;
  height: 1000px;
  display: flex;
  position: relative;
  z-index: 0;
  overflow: hidden;

  @include media-query(1199px) {
    height: 680px;
  }

  @include media-query(991px) {
    height: 600px;
  }

  @include media-query(767px) {
    height: 500px;
  }

  .slide-title {
    padding-top: 35px;
  }

  .hero-inner .container {
    position: relative;
  }

  .hero-inner {
    &::before {
      display: none;
    }
  }

  .slide-text p {
    @include media-query(1400px) {
      max-width: 600px;
    }
  }

  .lawyer-pic {
    position: absolute;
    right: -70px;
    bottom: -220px;
    z-index: 1;

    @include media-query(1600px) {
      right: 0;
    }

    .lawyer-shape {
      position: absolute;
      left: 0;
      top: 110px;
      width: 600px;
      height: 600px;
      background: #2f426b;
      border-radius: 50%;
      z-index: -1;

      @include media-query(1400px) {
        width: 500px;
        height: 500px;
      }
      @include media-query(1200px) {
        width: 400px;
        height: 400px;
      }

      .shape-1 {
        position: absolute;
        left: 6px;
        top: 35px;
        z-index: -99;
        transform: rotate(8deg);
        @include media-query(1400px) {
          left: 0;
          top: 13px;
        }
        @include media-query(1200px) {
          display: none;
        }
      }
      .shape-2 {
        position: absolute;
        right: 0;
        bottom: -70px;

        @include media-query(1200px) {
          display: none;
        }
      }
    }

    @include media-query(1400px) {
      width: 440px;
      height: 640px;
      bottom: -200px;
    }
    @include media-query(1199px) {
      width: 340px;
      height: 640px;
      bottom: -200px;
    }

    @include media-query(991px) {
      display: none;
    }
  }

  .swiper-slide .container {
    @include widther(992px) {
      padding-top: 10px;
    }
  }

  .slide-btns {
    display: flex;
    align-items: center;
  }

  .video-btn .wrap {
    display: block;
    width: 50px;
    height: 50px;
    line-height: 61px;
    text-align: center;
    background: $white;
    border-radius: 50%;
    margin-left: 30px;
    position: relative;
    border: 0;
    &:after {
      content: "";
      width: 0px;
      height: 0px;
      border-top: 9px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 14px solid #2f426b;
      position: absolute;
      left: 52%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    &:before {
      position: absolute;
      left: -10px;
      top: -10px;
      width: 140%;
      height: 140%;
      background: rgb(59, 81, 125);
      content: "";
      border-radius: 50%;
      z-index: -1;
      -webkit-animation: spineer 2s infinite;
      animation: spineer 2s infinite;
    }

    i {
      font-size: 25px;
      color: $white;
    }
  }
}

@-webkit-keyframes spineer {
  from {
    -webkit-box-shadow: 0 0 0 0 rgba(59, 81, 125, 0.99);
    box-shadow: 0 0 0 0 rgba(59, 81, 125, 0.99);
  }
  to {
    -webkit-box-shadow: 0 0 0 45px rgba(59, 81, 125, 0.01);
    box-shadow: 0 0 0 45px rgba(59, 81, 125, 0.01);
  }
}

@keyframes spineer {
  from {
    -webkit-box-shadow: 0 0 0 0 rgba(59, 81, 125, 0.99);
    box-shadow: 0 0 0 0 rgba(59, 81, 125, 0.99);
  }
  to {
    -webkit-box-shadow: 0 0 0 45px rgba(59, 81, 125, 0.01);
    box-shadow: 0 0 0 45px rgba(59, 81, 125, 0.01);
  }
}
