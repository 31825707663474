/*--------------------------------------------------------------
4. wpo-footer
--------------------------------------------------------------*/
.wpo-site-footer {
    background: #29395b;
    position: relative;
    font-size: 15px;
    overflow: hidden;

    ul {
        list-style: none;
    }

    p{
        color: $white;
    }
    li{
        color: $white;
    }

    .container {
        position: relative;
    }

    .wpo-upper-footer{
        padding: 70px 0;
    }

    @media (max-width: 991px) {

        .wpo-upper-footer{
            padding: 50px 0 0;
        }
    }
    
    @media (max-width: 767px) {
    
        .wpo-upper-footer{
            padding: 60px 0 0;
        }
    }
    
    @media (max-width: 991px) {
    
        .wpo-upper-footer .col{
            min-height: 235px;
            margin-bottom: 70px;
        }
    }
    
    @media (max-width: 767px) {
    
        .wpo-upper-footer .col{
            min-height: auto;
            margin-bottom: 60px;
        }
    }

    .widget-title{
        margin-bottom: 30px;
    }

    @media (max-width: 767px) {

        .widget-title{
            margin-bottom: 20px;
        }
    }


    .widget-title{
        h3{
            font-size: 30px;
            color: $white;
            margin: 0;
            position: relative;
            font-family: $heading-font;
            font-weight: 700;
        }
    }
    
    .about-widget {
        .logo {
            max-width: 180px;
        }
    }

    .about-widget {
        p{
            margin-bottom: 0.8em;
            line-height: 1.9em;

            &:last-child{
                margin-bottom: 0;
            }
        }
    }
    .wpo-service-link-widget{
        padding-left: 70px;
    }
        
    .link-widget {
        overflow: hidden;
        

        @media screen and (min-width: 1200px) {
            padding-left: 75px;
        }
        
        @media (max-width: 1199px) {
            padding-left: 20px;
        }
        
        @media (max-width: 991px) {
            padding-left: 0;
        }
        
        @media (max-width: 767px) {
            max-width: 350px;
        }

        ul{
            li{
                position: relative;
                a{
                    color: $white;
                    font-size: 15px;

                    &:hover{
                        color: $theme-primary-color;
                    }
                }
            }
            li+li{
              padding-top: 15px;
            }
        }
    }

    .tag-widget{
        ul{
            display: flex;
            flex-wrap: wrap;
            li{
                a{
                  display: block;
                  padding:8px 20px;
                  background: rgba(255,255,255,.1);
                  font-size: 16px;
                  color: $white;
                  margin-right: 10px;
                  margin-bottom: 10px;
                  border-radius: 5px;
                }
            }
        }
    }

.social-widget{
    ul{
        li{
            a{
                display: block;
                font-size: 16px;
                font-weight: 600;
                color: $white;
                margin-bottom: 10px;
                &:hover{
                    color: $theme-primary-color;
                }

                i{
                    margin-right: 10px;
                }
            }
        }
    }
}
    

    .wpo-lower-footer{
        text-align: center;
        position: relative;
        background: #213254;
    
        .row{
            padding: 20px 0;
            position: relative;
        }
    
        .copyright{
            display: inline-block;
            font-size: 15px;
            font-size: 0.9375rem;
            margin: 0;
    
            a{
                color: $white;
                text-decoration: none;
            }
    
            @media (max-width: 991px) {
                float: none;
                display: block;
            }
        }
    }
    
    .instagram{
        ul{
            display: flex;
            flex-wrap: wrap;
            margin: 0px -3px;
    
            li{
                -ms-flex: 0 0 33.33%;
                flex: 0 0 33.33%;
                max-width: 33.33%;
                margin-bottom: 5px;
                padding: 0px 3px;
    
                img{
                    width: 100%;
                }
            }
        }
    }
}






