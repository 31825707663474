.wpo-section-title {
  margin-bottom: 60px;

  @include media-query(767px) {
    margin-bottom: 40px;
  }

  span {
    text-transform: uppercase;
    font-size: 20px;
    color: $theme-primary-color;
    font-family: $heading-font;
  }

  h2 {
    font-size: 40px;
    line-height: 55px;
    margin: 0;
    margin-top: 15px;
    position: relative;
    text-transform: capitalize;
    font-family: $heading-font;
    padding-left: 40px;
    font-weight: 600;
    margin-top: 0;
    word-break: keep-all;

    &:before {
      position: absolute;
      left: 0;
      top: 0;
      width: 10px;
      height: 100%;
      background: $theme-primary-color;
      content: "";
    }

    @include media-query(767px) {
      font-size: 32px;
      line-height: 40px;
    }

    @include media-query(330px) {
      font-size: 30px;
    }
  }

  p {
    font-size: 18px;
  }
}
