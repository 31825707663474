/*---------------------------
	Fonts
----------------------------*/
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300;400;500;600;700&display=swap");

// @font-face {
//   font-family: "Muli";
//   src: url("../../fonts/text-font/Muli.ttf") format("truetype");
//   font-weight: 600;
//   font-style: normal;
// }

// /* #### Generated By: http://www.cufonfonts.com #### */

// @font-face {
//   font-family: "Muli";
//   font-style: normal;
//   font-weight: 500;
//   src: local("Muli Regular"), url("../../fonts/text-font/Muli-Regular.woff") format("woff");
// }

// @font-face {
//   font-family: "Muli";
//   font-style: normal;
//   font-weight: 600;
//   src: local("Muli SemiBold"), url("../../fonts/text-font/Muli-SemiBold.woff") format("woff");
// }

// @font-face {
//   font-family: "Muli";
//   font-style: normal;
//   font-weight: 700;
//   src: local("Muli Bold"), url("../../fonts/text-font/Muli-Bold.woff") format("woff");
// }

@font-face {
  font-family: "Muli";
  src: url("../../fonts/text-font/GodoM.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
}

/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: "Muli";
  font-style: normal;
  font-weight: 500;
  src: local("Muli Regular"), url("../../fonts/text-font/GodoM.otf") format("opentype");
}

@font-face {
  font-family: "Muli";
  font-style: normal;
  font-weight: 600;
  src: local("Muli SemiBold"), url("../../fonts/text-font/GodoM.otf") format("opentype");
}

@font-face {
  font-family: "Muli";
  font-style: normal;
  font-weight: 700;
  src: local("Muli Bold"), url("../../fonts/text-font/GodoB.otf") format("opentype");
}

// fonts
$base-font-size: 15;
$base-font: "Muli";
// $heading-font: "Cormorant Garamond", serif;
$heading-font: "Muli";

// color
$dark-gray: #070143;
$body-color: #687693;
$white: #fff;
$light: #8188a9;
$black: #000;
$small-black: #333;
$cyan: #848892;

$theme-primary-color: #e3b576;
$theme-primary-color-s2: #071e93;
$body-bg-color: #fff;
$section-bg-color: #fafbfe;
$section-bg-color-s2: rgba(48, 65, 104, 0.8);
$text-color: #6e6e6e;
$text-light-color: #676767;
$heading-color: $dark-gray;
$border-color: #ebebeb;
$border-color-s2: #d8e0f1;
