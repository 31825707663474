/*======================================
 5. Home-style-2 
 =======================================*/

/* 5.1	wpo-features-section-s2 */

.wpo-features-section-s2 {
  padding-top: 120px;
  background: $white;
  @media (max-width: 767px) {
    padding-top: 80px;
  }
  .wpo-features-wrapper {
    margin-top: 0;
    margin-bottom: 0;
    top: 0;

    .wpo-features-item {
      padding: 50px 40px;
      text-align: center;

      @media (max-width: 1200px) {
        padding: 30px 10px;
      }

      .wpo-features-icon {
        float: none;

        width: 100px;
        height: 100px;
        line-height: 135px;
        background-color: rgb(255, 255, 255);
        box-shadow: 0px 5px 15px 0px rgba(62, 65, 159, 0.1);
        border-radius: 50%;
        margin: 0 auto;
        margin-bottom: 20px;
      }
    }
  }
}

/* 5.2	wpo-about-section */

.wpo-about-section {
  background: $section-bg-color;
  .wpo-about-img {
    img {
      width: 100%;
    }
  }
  .wpo-about-content {
    padding-left: 50px;

    @media (max-width: 991px) {
      padding-left: 0;
      margin-top: 50px;
    }
    .wpo-about-content-inner {
      p {
        font-size: 25px;
        word-break: keep-all;
      }
    }

    .signeture {
      margin-top: 40px;

      h4 {
        font-size: 22px;
        font-family: $base-font;
        font-weight: 700;
        color: #5c6c8c;
      }

      p {
        font-size: 16px;
        color: #687693;
        margin-bottom: 30px;
      }
    }
  }

  &.section-bg-blue {
    background: $section-bg-color-s2;
    .wpo-about-content {
      .wpo-section-title {
        h2 {
          color: $white;
        }
      }
      .wpo-about-content-inner {
        p {
          font-size: 25px;
          color: $white;
          word-break: keep-all;
        }
      }
      .signeture {
        h4 {
          color: $white;
        }

        p {
          color: $white;
        }
      }
    }
  }
}

/* 5.3	wpo-service-section-s2 */

.wpo-service-section-s2 {
  padding-bottom: 90px;

  @media (max-width: 991px) {
    padding-bottom: 50px;
  }

  .wpo-service-item {
    background: $white;
    box-shadow: 0px 5px 15px 0px rgba(62, 65, 159, 0.1);
    padding: 50px;
    margin-bottom: 30px;

    @media (max-width: 1200px) {
      padding: 30px 25px;
    }

    .wpo-service-icon {
      width: 82px;
      height: 82px;
      background: #f5f5f7;
      line-height: 110px;
      text-align: center;
      @include rounded-border(50%);

      .fi {
        &:before {
          font-size: 40px;
          color: $theme-primary-color;
        }
      }

      img {
        width: 50px;
        line-height: 50px;
        height: 50px;
      }
    }

    .wpo-service-text {
      h2 {
        font-size: 30px;
        font-weight: 600;
        margin: 20px 0;

        a {
          color: $heading-color;

          &:hover {
            color: $theme-primary-color;
          }
        }
      }
      p {
        margin-bottom: 0;
      }
    }
  }
}

/* 5.4	wpo-fun-fact-section */

.wpo-fun-fact-section {
  text-align: center;
  background: url(../../images/funfact.jpg) no-repeat center center;
  position: relative;
  background-size: cover;
  z-index: 1;
  @media (max-width: 767px) {
    padding-top: 60px;
  }
  &:before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(22, 57, 103, 0.9);
    content: "";
    z-index: -1;
  }

  @include media-query(767px) {
    padding-bottom: 30px;
  }

  h2 {
    font-size: 30px;
    font-size: calc-rem-value(30);
    font-weight: 600;
    margin: 0 0 1.8em;

    @include media-query(991px) {
      font-size: 26px;
      font-size: calc-rem-value(26);
    }

    @include media-query(767px) {
      font-size: 25px;
      font-size: calc-rem-value(25);
    }
  }

  .wpo-fun-fact-grids .grid {
    width: 25%;
    float: left;

    @include media-query(767px) {
      width: 50%;
      margin-bottom: 50px;
    }
  }

  .grid {
    h3 {
      font-size: 60px;
      font-size: calc-rem-value(60);
      font-weight: 700;
      color: $theme-primary-color;
      margin: 0 0 0.2em;
      font-family: $heading-font;
      display: flex;
      align-items: center;
      justify-content: center;

      @include media-query(1200px) {
        font-size: 50px;
        ont-size: calc-rem-value(50);
      }

      @include media-query(991px) {
        font-size: 40px;
        font-size: calc-rem-value(40);
      }

      @include media-query(767px) {
        font-size: 35px;
        font-size: calc-rem-value(35);
      }
    }
  }

  .grid h3 + p {
    font-size: 20px;
    font-size: calc-rem-value(20);
    margin: 0;
    color: $white;

    @include media-query(1200px) {
      font-size: 20px;
      font-size: calc-rem-value(20);
    }

    @include media-query(991px) {
      font-size: 16px;
      font-size: calc-rem-value(16);
    }
  }
}
