.patent-modal {
  .contents-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .header-title {
      width: calc(100% - 32px);
      font-size: 30px;
      font-weight: 600;
      color: #333;
      text-align: center;
    }

    .header-close {
      font-size: 20px;
      cursor: pointer;

      button {
        background-color: transparent;
        border: none;
        outline: none;

        .ti-close {
          font-weight: 700;
        }
      }
    }
  }

  .contents-title {
    font-size: 20px;
    font-weight: 500;
    color: #333;
    margin-top: 25px;
  }

  .contents-box {
    padding: 20px;
    background-color: #cdcdcd;

    p.contents-subtitle {
      font-size: 16px;
      font-weight: 500;
      color: #333;
      margin-bottom: 5px;
    }

    p.contents-info {
      font-size: 15px;
      font-weight: 400;
      margin-left: 17px;
    }

    ul {
      line-height: 1.8;
      padding-left: 20px;
      li {
        list-style-type: square;
      }
    }
  }
}

.myinfo-modal {
  .contents-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .header-title {
      width: calc(100% - 32px);
      font-size: 30px;
      font-weight: 600;
      color: #333;
      text-align: center;
    }

    .header-close {
      font-size: 20px;
      cursor: pointer;

      button {
        background-color: transparent;
        border: none;
        outline: none;

        .ti-close {
          font-weight: 700;
        }
      }
    }
  }

  .contents-title {
    font-size: 18px;
    font-weight: 500;
    margin-top: 25px;
  }

  .pwd-change-btn {
    width: 100%;
    height: 50px;
    border: none;
    outline: none;
    color: #1c193f;
    background: #f3f3f3;
    font-size: 20px;
    font-weight: 600;

    &:hover {
      background: #e3b576;
      color: #fff;
    }
  }
}

.loading-modal {
  @keyframes loading-icon {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
  .loading-icon div {
    position: absolute;
    width: 120px;
    height: 120px;
    border: 20px solid #18298f;
    border-top-color: transparent;
    border-radius: 50%;
  }
  .loading-icon div {
    animation: loading-icon 1.25s linear infinite;
    top: 100px;
    left: 100px;
  }
  .loading {
    width: 200px;
    height: 200px;
    display: inline-block;
    overflow: hidden;
    background: none;
  }
  .loading-icon {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
  }
  .loading-icon div {
    box-sizing: content-box;
  }
}

.payment-modal {
  width: 1080px;

  @media screen and (max-width: 1080px) {
    width: 100%;
  }
}
