.wpo-mypage-section {
  .apply-info {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;

    .apply-info-box {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: center;
      padding: 20px;
      border-right: 1px solid #e5e5e5;

      &:hover,
      &.active {
        background-color: rgba(22, 57, 103, 0.9);
        p,
        .apply-count {
          color: white;
        }
        cursor: pointer;
      }

      p {
        color: black;
      }

      .apply-count {
        font-size: 50px;
        font-weight: bold;
        line-height: 0.5;

        span {
          font-size: 14px;
          font-weight: normal;
        }
      }
    }
  }

  table.apply-list-temp {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
    border-collapse: collapse;

    th {
      height: 80px;
      font-weight: 700;
      border-bottom: 1px solid #e5e5e5;
      padding: 40px 0;
      text-align: center;
    }

    tr {
      height: 80px;
      padding: 40px 0;
      text-align: center;

      &.item {
        cursor: pointer;

        &:hover {
          background-color: rgba(22, 57, 103, 0.9);
          color: white;
        }
      }

      &:not(:last-child) {
        border-bottom: 1px solid #e5e5e5;
      }
    }
  }

  div.apply-list-temp {
    padding: 10px;
    .apply-list-box {
      box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
      border-radius: 10px;
      padding: 20px;
      color: #1d2327;

      h2 {
        font-size: 27px;
        font-weight: 700;
        color: #1d2327;
        margin-top: 0;
        margin-bottom: 25px;
        text-align: center;
        background: #eee;
        padding: 20px;

        @media screen and (max-width: 767px) {
          font-size: 20px;
        }
      }

      ul {
        li {
          font-size: 17px;
          font-weight: 700;
          margin-bottom: 15px;
          list-style: none;

          span {
            font-weight: 400;
            list-style: none;
          }

          @media screen and (max-width: 767px) {
            font-size: 14px;
          }
        }
      }
    }
  }

  table.apply-list-success {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
    border-collapse: collapse;

    th {
      height: 80px;
      font-weight: 700;
      border-bottom: 1px solid #e5e5e5;
      padding: 40px 0;
      text-align: center;
    }

    tr {
      height: 80px;
      padding: 40px 0;
      text-align: center;

      &.item {
        cursor: pointer;

        &:hover {
          background-color: rgba(22, 57, 103, 0.9);
          color: white;
        }
      }

      &:not(:last-child) {
        border-bottom: 1px solid #e5e5e5;
      }
    }
  }

  .apply-list-success {
    padding: 10px;
    .apply-list-box {
      box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
      border-radius: 10px;
      padding: 20px;

      h2 {
        font-size: 27px;
        font-weight: 700;
        color: #1d2327;
        margin-top: 0;
        margin-bottom: 25px;
        text-align: center;
        background: #eee;
        padding: 20px;

        @media screen and (max-width: 767px) {
          font-size: 20px;
        }
      }

      ul {
        li {
          font-size: 17px;
          color: #1d2327;
          font-weight: 700;
          margin-bottom: 15px;
          list-style: none;

          span {
            color: #1d2327;
            font-weight: 400;
            list-style: none;
          }

          @media screen and (max-width: 767px) {
            font-size: 14px;
          }
        }
      }
    }
  }

  button.download-btn {
    padding: 8px 15px;
    color: #1c193f;
    background: #f3f3f3;
    font-size: 15px;
    border-radius: 3px;
    font-weight: 600;
    border: 0;
    outline: 0;

    &:hover {
      background: #e3b576;
      color: #fff;
    }
  }

  button.payment-btn {
    padding: 8px 15px;
    color: #1c193f;
    background: #f3f3f3;
    font-size: 15px;
    border-radius: 3px;
    font-weight: 600;
    border: 0;
    outline: 0;
    margin-left: 10px;

    &:hover {
      background: #e3b576;
      color: #fff;
    }
  }
}
