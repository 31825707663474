.checkoutWrapper {
    .checkoutCard {
        margin-bottom: 20px;

        button.collapseBtn {
            color: #272424;
            height: 50px;
            font-size: 16px;
            letter-spacing: 0;
            display: block;
            text-align: left;
            text-transform: capitalize;
            font-weight: 600;
            border: 1px solid #dedddd;
            border-radius: 0;
            background: #fff;
            box-shadow: 0 0 15px rgba(0, 0, 0, 0.03);
            padding: 0 15px;

            i {
                float: right;
                margin-top: 4px;
                color: #8e8d8a;
            }

            @media screen and (max-width: 426px) {
                font-size: 12px;
                letter-spacing: -1px;
                padding: 0 5px;
            }
        }

        .chCardBody {
            padding: 15px;

            .formSelect {
                div {
                    background: transparent;
                    height: 27px;
                    padding: 0;
                    line-height: 30px;
                    text-transform: capitalize;
                    font-weight: 400;
                    letter-spacing: 0;
                    color: #555;
                    font-size: 13px;
                }
            }

            .checkBox {
                span {
                    font-size: 14px;
                    letter-spacing: 0;
                }
            }

            .note {
                > div {
                    margin-top: 30px;
                }

                textarea {
                    height: 100px !important;
                    padding: 0;
                }
            }

            .paymentMethod {
                display: flex;
                flex-direction: row;

                label {
                    span {
                        font-size: 13px;
                        font-weight: 500;
                    }
                }
            }

            label {
                font-size: 14px;
                letter-spacing: 0;
                font-weight: 500;
                transform: scale(1);
            }

            input,
            textarea {
                height: 25px;
                background: transparent;
                padding-left: 10px;
                font-weight: 500;
                letter-spacing: 0;
                color: #444444;
                font-size: 13px;
            }

            p {
                font-size: 14px;
                font-weight: 500;
                color: #555555;
            }
        }
    }

    .cuponWrap {
        .cuponForm {
            margin-top: 10px;

            button {
                margin-top: 10px;
            }
        }
    }

    .cardType {
        display: flex;
        justify-content: space-between;
        margin: 0 -5px 20px;

        .cardItem {
            margin: 5px;
            flex-basis: calc(100% * (1 / 4) - 10px);
            text-align: center;
            border: 1px solid #ddd;
            padding: 18px 0;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: center;
            cursor: pointer;

            &.active {
                background: #f9f9f9;
            }
        }
    }

    .warning-field {
        font-size: 14px;
        float: right;
        margin-top: 10px;

        @media screen and (max-width: 426px) {
            float: none;
            display: block;
        }
    }

    .required-field {
        color: #ff0000 !important;
        font-size: 14px !important;

        &.ml-5 {
            margin-left: 5px;
        }
    }

    .focus-field {
        color: #00418d;
    }

    .policy-field {
        background-color: #f9f9f9;
        padding: 10px;

        p {
            margin: 10px;
        }
    }

    .file-box {
        border: 2px dotted #ccc;
        padding: 20px;
    }
}

.cuponWrapper {
    padding: 30px;
    box-shadow: 0px 5px 15px 0px rgba(62, 65, 159, 0.1);

    h4 {
        font-weight: 700;
        margin-bottom: 20px;
        font-size: 24px;
    }

    table {
        tr {
            td {
                border-color: rgb(243, 243, 243);
            }
            &:last-child {
                td {
                    border-bottom: 0;
                }
            }
        }
    }

    @media screen and (max-width: 426px) {
        padding: 10px;
    }
}

.cBtn.cBtnBlack {
    padding: 9px 60px;
    background: #e3b576;
    font-size: 16px;
    font-weight: 600;

    &:hover {
        background: #dfaa61;
    }

    &.mt-20 {
        margin-top: 20px;
    }
}
